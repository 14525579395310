import React, { useState, useEffect } from "react";
import { graphql } from 'gatsby'

import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import NewsHeader from "../Components/NewsLanding/NewsHeader";
import NewsLanding from "../Components/NewsLanding/NewsLanding";
import SEO from "../Components/Seo/seo"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/grunticon.scss";

const NewsLandingTemplate = (props) => {

  const GQLPage = props.data.glstrapi?.articles;
  const GQLConfig = props.data.glstrapi?.globalConfig;
  const blogs = props.data.glstrapi?.newsBlogs;
  const blogCategory = props.data.glstrapi?.newsCategories;
  const PageModules = GQLPage && GQLPage.length > 0 && GQLPage[0]?.Modules[0];

 
   const categorySlug = blogCategory && blogCategory.length > 0 && blogCategory[0]?.URL;
   const categoryName = blogCategory && blogCategory.length > 0 && blogCategory[0]?.Name;
  
    return  (
        <React.Fragment>
        <SEO title={PageModules?.Meta_Title ? PageModules?.Meta_Title : `Latest Property News | Balgores`} description= {PageModules?.Meta_Description ? PageModules?.Meta_Description : `If you would like to keep up to date with the property world, check out our news page to stay informed.`}/>                
        <section id="body-bg-Grey" className="wrapper news-landing-page">
             <div className="home-header">
                <Header title={PageModules?.Intro_Title ? PageModules?.Intro_Title : 'Blog'} GQLConfig={GQLConfig} />
            </div>            
            <NewsHeader title={PageModules?.Intro_Title ? PageModules?.Intro_Title : 'Blog'} content={ PageModules?.Intro_Content ? PageModules?.Intro_Content : ''} categorySlug={categorySlug} categoryName={categoryName} blogs={blogs} />
            <NewsLanding categorySlug={categorySlug} categoryName={categoryName}  />
            <Footer GQLConfig={GQLConfig} />
        </section>
        </React.Fragment>
    )
};
export default NewsLandingTemplate;
export const newsLandingQuery = graphql`
query NewsLandingQuery ($slug: String!) {
  glstrapi {
    globalConfig {
        Sidebar_Title
        Sidebar_Title_Description
        Toogle_Description
        Info_Toogle
        Email_Postcode_Mapping
    }
    articles(where:{Title:"Blog"}, publicationState: LIVE) {
      id
      Title
      Meta_Title
      Meta_Description
      Body_Background_Color
      Modules {
        ... on GLSTRAPI_ComponentComponentsBlogIntro {
          id
          Intro_Title
          Intro_Content
        }
      }
      Select_Popular_Search
    }
    newsBlogs(where: {Select_News_Category:{URL:$slug}}, publicationState: LIVE, sort: "News_Published_Date:desc") {
        id
        Title
        URL
        Short_Intro
        News_Published_Date
        Tile_Image {
          url
          alternativeText
        }
        Select_News_Category {
          Name
          URL
        }
        imagetransforms
    }
    newsCategories(where: {URL:$slug}, publicationState: LIVE) {
        id
        Name
        URL
    }
  }
}
`;